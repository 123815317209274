import { useState } from 'react';
import { Box, TextField, Grid, Typography } from '@mui/material';
import { EmptyItems } from 'components/EmptyItems';
import StyledCard from 'components/Card';
import { ListItem } from '../ListItem';
import { TableHeading, ListWrapper, Item } from './styles';

export const List = ({
  items,
  btnText,
  btnText2,
  onApply = (listing) => {},
  onAction2 = (listing) => {}
}) => {
  const hasItems = items && items.length > 0;
  return (
    <>
    {
      hasItems ? (
        <>
          <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {items.map((item, index) => (
              <Grid key={index} item xs={12} sm={4} md={3}>
                <StyledCard key={index} data={item} btnText={btnText} btnText2={btnText2} onAction2={() => onAction2(item)} onApply={() => onApply(item)} />
              </Grid>
            ))}
          </Grid>
        </>
      ) : <Box><Typography>No items found</Typography></Box>
    }
    </>
  );
}