import * as SOLLIEN_TYPES from "../idl.json";

export const SOLLIEN_PROGRAM = SOLLIEN_TYPES;
export const SOLLIEN_PROGRAM_ID = "9BpjYHYmoaZtheJaz59F3UT2zZor7aHWXzVF5erqb3SS";
export const CARDINAL_TOKEN_MANAGER_PROGRAM_ID = "vtmaopgPztD3WrNgYNrGFbtfUkcgVSxk6U8eycqQTPD";
export const CARDINAL_PAID_APPROVER_PROGRAM_ID = "vpcKZrRAFr73Ty4W4bFM4xCWhxk1Whd9mHB1uuvFAKQ";
export const CARDINAL_TIME_INVALIDATOR_PROGRAM_ID = "vtiRVcPp7TjXUFgCwFGMDgKLZdbqY4dZJiWKYCv9Gb8";
export const CARDINAL_CRANK_PROGRAM_ID = "crk3AZsrZop64dURFeUykcVSynh2z9Cgh6zneLhcdj1";
export const CARDINAL_CRANK_ADDRESS = "EBEhYotBqZt4giEDA748BFGE4ALJFESkKoyuHttcZx5t";
export const MINT = "5yzFo4hSRtsRFLfgbehPFjFiaAGWNTuW67QUMd8MdZkH";
// Get paymint mint tokens for testing: https://spl-token-faucet.com/?token-name=USDC
export const PAYMENT_MINT = "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr";
export const PAYMENT_DECIMALS = 6;

export const MINT_COUNTER_SEED = "mint-counter";
export const MINT_MANAGER_SEED = "mint-manager";
export const CLAIM_RECEIPT_SEED = "claim-receipt";
export const TOKEN_MANAGER_SEED = "token-manager";
export const TIME_INVALIDATOR_SEED = "time-invalidator";
export const CLAIM_APPROVER_SEED = "paid-claim-approver";

export const DEFAULT_EXTENSION = 3600;
