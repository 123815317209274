import { Modal, Box, Typography, Divider, Button, CircularProgress } from '@mui/material';
import { rent, getProvider } from 'utils';
import { StyledBox, StyledFooter } from './styles';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { StyledButton } from 'components/Button';
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';

export const ApplyModal = ({ open, handleClose, listing }) => {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [status, setStatus] = useState('success');
  const [showAlert, setShowAlert] = useState(false);
  const { sendTransaction } = useWallet();

  const wallet = useWallet();
  const { connection } = useConnection();

  const handleApply = async () => {
    setLoading(true);
    const tokenManagerId = listing?.publicKey;
    try {
      if (!wallet.connected) throw new WalletNotConnectedError();
      const tx = await rent(connection, wallet, { tokenManagerId });
      const txSignature = await sendTransaction(tx, connection);
      const txHash = await connection.confirmTransaction(txSignature, 'processed');

      setLoading(false);
      setShowAlert(true);
      setAlertMessage("Transaction successful")
      setStatus('success');
    } catch (e) {
      setLoading(false);
      setShowAlert(true);
      setStatus('error');
      if (e instanceof WalletNotConnectedError) {
        setAlertMessage("Please connect wallet");
      } else {
        setAlertMessage(e.message);
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <StyledBox>
        <Typography variant='h2'>Apply For Token</Typography>
        <Divider sx={{ margin: '12px 0' }} />
        {showAlert && <Alert sx={{ margin: '12px 0' }} severity={status} onClose={() => {setShowAlert(false)}}>{alertMessage}</Alert>}
        <div>
          You are renting {listing.mintInfo?.name} for {listing.dailyPrice} USDC. You will be required to pay initial deposit of  {listing.securityDeposit} USDC.
        </div>
        <StyledFooter>
          <StyledButton
            onClick={handleClose}
            disabled={loading}
          >
            Cancel
          </StyledButton>
          <StyledButton
            size='large'
            variant='contained'
            disabled={loading}
            onClick={handleApply}
          >
            {
              loading ? <CircularProgress size={24} /> : 'Apply'
            }
          </StyledButton>
        </StyledFooter>
      </StyledBox>
    </Modal>
  );
};
