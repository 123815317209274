import { useTheme } from '@emotion/react';
import { StyledWrapper, PageHeader } from './styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { List } from 'components/List';
import { Typography, Box } from '@mui/material';
import { getProvider } from 'utils';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { getMyRentals } from 'dataProviders';
import { pay_rent } from 'utils/pay_rent';
import { withdraw } from 'utils/withdraw';
import { Loader } from 'components/Loader';

import Snackbar from '@mui/material/Snackbar';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';

function MyRentals() {
  const [listings, setListings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(true);
  const [alertMessage, setAlertMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { sendTransaction } = useWallet();
  const { connection } = useConnection();

  const fetchData = useCallback(async (connection) => {
    const data = await getMyRentals(connection, wallet.publicKey);
    console.log(data);
    if (data) {
      setLoading1(false);
      setListings(data);
    }
  }, []);

  const toggleApplyModal = async (listing) => {
    setLoading(true);
    const tokenManagerId = listing?.publicKey;
    const numberOfDays = 1;
    try {
      if (!wallet.connected) throw new WalletNotConnectedError();
      const tx = await pay_rent(connection, wallet, {
        tokenManagerId,
        numberOfDays,
      });
      const txSignature = await sendTransaction(tx, connection);
      const txHash = await connection.confirmTransaction(
        txSignature,
        'processed'
      );

      setShowAlert(true);
      setAlertMessage('Transaction successful');
    } catch (e) {
      console.log(e);
      setLoading(false);
      setShowAlert(true);
      if (e instanceof WalletNotConnectedError) {
        setAlertMessage('Please connect wallet');
      } else {
        setAlertMessage(e.message);
      }
    }
  };

  const onWithdraw = async (listing) => {
    setLoading(true);
    const mint = listing?.mint;
    try {
      if (!wallet.connected) throw new WalletNotConnectedError();
      const tx = await withdraw(connection, wallet, { mint });
      const txSignature = await sendTransaction(tx, connection);
      const txHash = await connection.confirmTransaction(
        txSignature,
        'processed'
      );

      setShowAlert(true);
      setAlertMessage('Transaction successful');
    } catch (e) {
      console.log(e);
      setLoading(false);
      setShowAlert(true);
      if (e instanceof WalletNotConnectedError) {
        setAlertMessage('Please connect wallet');
      } else {
        setAlertMessage(e.message);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };

  const wallet = useWallet();

  /* ComponentDidMount*/
  useEffect(() => {
    fetchData(connection);
  }, []);
  return (
    <StyledWrapper>
      <PageHeader>
        <Typography variant='h2' color='white'>
          My Rentals
        </Typography>
      </PageHeader>
      {!loading1 ? (
        <List
          items={listings}
          btnText='Pay Rent'
          btnText2='Return'
          onApply={toggleApplyModal}
          onAction2={onWithdraw}
        />
      ) : (
        <Box sx={{ height: 'calc(100vh - 320px)' }}>
          <Loader color='primary' />
        </Box>
      )}
      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleClose}
        message={alertMessage}
      />
    </StyledWrapper>
  );
}

export default MyRentals;
