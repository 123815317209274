import { styled } from '@mui/material/styles';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputBase,
} from '@mui/material';

const sizeWidthMap = {
  large: '500px',
  medium: '400px',
  small: '300px',
};

const sizeHeightMap = {
  large: '50px',
  medium: '46px',
  small: '42px',
};

const sizeXPaddingMap = {
  large: '20px',
  medium: '18px',
  small: '15px',
};

const iconSizeMap = {
  large: '20px',
  medium: '18px',
  small: '16px',
};

export const StyledFormControl = styled(FormControl)(({ fullWidth, size }) => ({
  width: !fullWidth ? sizeWidthMap[size] : '100%',
  marginBottom: '16px'
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.red75,
  marginLeft: '0px',
  marginTop: '10px',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '17px',
  opacity: '0.8',

  '& svg': {
    marginRight: '5px',
  },
}));

export const StyledInputStartAdornment = styled(InputAdornment)(({ size }) => ({
  paddingLeft: sizeXPaddingMap[size],
  '& svg': {
    width: iconSizeMap[size],
  },
}));

export const StyledInputAdornment = styled(InputAdornment)(({ size }) => ({
  paddingRight: sizeXPaddingMap[size],
  '& svg': {
    width: iconSizeMap[size],
  },
}));

export const StyledInputBase = styled(InputBase)(
  ({ theme, size, icon, starticon, fullWidth, color, error }) => ({
    '& .MuiInputBase-input': {
      padding: `15px ${sizeXPaddingMap[size]}`,
      paddingRight: !icon ? sizeXPaddingMap[size] : '0px',
      paddingLeft: !starticon ? sizeXPaddingMap[size] : '5px',
      color: theme.palette.primary.white,
      fontFamily: 'GTAmerica',
      fontWeight: 500,
      fontSize: '14px',
      fontStyle: 'normal',
      lineHeight: '17px',
      width: !fullWidth ? sizeWidthMap[size] : '100%',
      height: sizeHeightMap[size],
      boxSizing: 'border-box',

      '&::placeholder': {
        color: theme.palette.primary.gray50,
        opacity: 1,
      },
    },

    backgroundColor: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.green}`,
    borderRadius: '8px',

    boxSizing: 'border-box',

    resize: 'none',
    '&:hover': {
      borderColor: !error
        ? theme.palette.primary.main
        : theme.palette.primary.red75,
    },
    '&.Mui-focused': {
      boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
      borderColor: !error
        ? color
          ? color
          : theme.palette.primary.main
        : theme.palette.primary.red75,
    },
    '&.Mui-error': {
      border: `1px solid ${theme.palette.primary.red75}`,
    },
  })
);

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '140%',
  marginBottom: '8px',

  '&.Mui-focused': {
    color: theme.palette.primary.gray75,
  },
}));
