export const getStyles = (theme) => ({
  card: {
    width: '340px',
    minWidth: '340px',
    background: 'transparent none repeat scroll 0% 0% !important',
    filter: 'drop-shadow(rgba(0, 0, 0, 0.08) 0rem 0.5rem 0.938rem)',
    color: theme.palette.primary.gray,
    border: `1px solid rgb(209, 217, 217) !important`,
    borderRadius: '0.625rem',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '.MuiTypography-root': {
      marginBottom: '0px'
    }
  },
  header: {
    color: theme.palette.primary.white,
    marginBottom: '16px !important'
  },
  fadeChip: {
    padding: '4px 16px',
    backgroundColor: theme.palette.primary.gray25,
    color: theme.palette.primary.main,
    borderRadius: '8px',
    '.MuiTypography-root': {
      fontWeight: '400'
    }
  }
});