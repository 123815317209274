import {
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  Transaction,
} from '@solana/web3.js';
import {  web3 } from '@project-serum/anchor';
import {  findAta, withClaimToken } from '@onchain_org/token-manager';
import { timeInvalidator, tokenManager } from '@onchain_org/token-manager/dist/cjs/programs';
import { withRemainingAccountsForPayment } from '@onchain_org/token-manager/dist/cjs/programs/tokenManager';
import { extendExpiration } from '@onchain_org/token-manager/dist/cjs/programs/timeInvalidator/instruction';
import { DEFAULT_EXTENSION } from './constants';

export const rent = async (connection, wallet, { tokenManagerId }) => {
  const transaction = new web3.Transaction();
  const tokenManagerKey = new PublicKey(tokenManagerId)
  const claimTransaction = await withClaimToken(
    transaction,
    connection,
    wallet,
    tokenManagerKey,
  );

  const [timeInvalidatorId] =
    await timeInvalidator.pda.findTimeInvalidatorAddress(tokenManagerKey);

    const [timeInvalidatorData, tokenManagerData] = await Promise.all([
      timeInvalidator.accounts.getTimeInvalidator(connection, timeInvalidatorId),
      tokenManager.accounts.getTokenManager(connection, tokenManagerKey),
    ]);

    const payerTokenAccountId = await findAta(
      timeInvalidatorData.parsed.extensionPaymentMint,
      wallet.publicKey
    );

    const paymentAccounts = await withRemainingAccountsForPayment(
      transaction,
      connection,
      wallet,
      timeInvalidatorData.parsed.extensionPaymentMint,
      tokenManagerData.parsed.issuer,
      timeInvalidatorData.parsed.paymentManager,
      tokenManagerData.parsed.receiptMint
    );

    const extendExpInstruction = await extendExpiration(connection, wallet, tokenManagerKey, timeInvalidatorData.parsed.paymentManager, payerTokenAccountId, timeInvalidatorId, DEFAULT_EXTENSION, paymentAccounts)
    console.log(extendExpInstruction);
  return claimTransaction.add(extendExpInstruction);
};
