import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'theme';
import { AppRoutes } from 'routes';
import { useMemo, useState } from 'react';
import { clusterApiUrl, Connection, PublicKey } from '@solana/web3.js';
import { Program, AnchorProvider, web3 } from '@project-serum/anchor';
import {
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {
  useWallet,
  WalletProvider,
  ConnectionProvider,
} from '@solana/wallet-adapter-react';
import {
  WalletModalProvider,
  WalletMultiButton,
  WalletDisconnectButton,
} from '@solana/wallet-adapter-react-ui';
import { SOLLIEN_PROGRAM_ID } from 'utils';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
require('@solana/wallet-adapter-react-ui/styles.css');

const { SystemProgram, Keypair } = web3;
/* create an account  */
const opts = {
  preflightCommitment: 'processed',
};

const AppWithProvider = () => {
  const network = process.env.REACT_APP_NETWORK === 'mainnet' ?   WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet;
  const wallet = useWallet();
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <AppRoutes />
            </ThemeProvider>
          </BrowserRouter>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default AppWithProvider;
