import { withExtendExpiration } from '@onchain_org/token-manager';
import { web3 } from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';
import { DEFAULT_EXTENSION } from './constants';

export const pay_rent = async (connection, wallet, {
    tokenManagerId, numberOfDays
}) => {
    const transaction = new web3.Transaction();
    const extendExpirationTx = await withExtendExpiration(transaction, connection, wallet, new PublicKey(tokenManagerId), numberOfDays * DEFAULT_EXTENSION)
    return extendExpirationTx;
};
