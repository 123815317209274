import { forwardRef } from 'react';
import { Select, FormControl, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { ErrorWrapper } from '../ErrorWrapper';
import { TextInput } from '../TextInput';
import {
  selectStyles,
  menuProps,
  getPlaceholderStyles,
  StyledFormLabel,
  StyledInputLabel,
} from './styles';
import { ReactComponent as ChevronDown } from 'images/chevron-down.svg';

export const SelectInput = forwardRef(
  (
    { hasErrors, value, label, fieldLabel, placeholderStyles, ...rest },
    ref
  ) => {
    const theme = useTheme();

    return (
      <ErrorWrapper>
        <FormControl fullWidth {...rest}>
          {fieldLabel && (
            <StyledFormLabel shrink='false'>{fieldLabel}</StyledFormLabel>
          )}
          {value === '' && (
            <StyledInputLabel
              shrink={false}
              sx={getPlaceholderStyles(fieldLabel)}
              id='selectLabelId'
              {...placeholderStyles}
            >
              {label}
            </StyledInputLabel>
          )}
          <Select
            IconComponent={ChevronDown}
            input={<TextInput fullWidth haserrors={!!hasErrors} />}
            MenuProps={menuProps(theme)}
            label={label}
            labelId='selectLabelId'
            value={value}
            {...rest}
            sx={selectStyles(theme)}
            ref={ref}
          />
        </FormControl>
      </ErrorWrapper>
    );
  }
);

SelectInput.propTypes = {
  hasErrors: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldLabel: PropTypes.string,
};
