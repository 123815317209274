import { Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
import Main from 'pages/Main';
import MyListings from 'pages/MyListings';
import MyRentals from 'pages/MyRentals';

export function AppRoutes() {
  return (
    <Routes>
      <Route path='' element={<Main />}>
        <Route path='/' element={<Home />} />
        <Route path='/my-listings' element={<MyListings />} />
        <Route path='/my-rentals' element={<MyRentals />} />
      </Route>
    </Routes>
  );
}
