import { Box, padding, styled } from "@mui/system";

export const StyledWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: '24px',
  boxSizing: 'border-box',
  color: theme.palette.primary.white
}));

export const PageHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 0px 24px',
  marginBottom: '24px',
  color: theme.palette.primary.white
}));