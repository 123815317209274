import { Box } from "@mui/material";
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh'
}));

export const Content = styled(Box)(({
  overflow: 'auto',
  flex: '1 1 auto',
  position: 'relative'
}));