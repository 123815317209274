import { Typography } from "@mui/material";
import { StyledTopNav, StyledNavBar } from "./styles";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from "@solana/wallet-adapter-react";
import { Link, useLocation } from "react-router-dom";
import { StyledButton } from "components/Button";

const pages = [{ label: 'My Rentals', route: '/my-rentals' }, { label: 'My Listings', route: '/my-listings'}];

export const TopNav = () => {
  const wallet = useWallet();
  const location = useLocation();

  return (
    <StyledTopNav>
      <div><Typography variant="h2" color='white'><a href="/" target="_self">Vera</a></Typography></div>
      <StyledNavBar>
        {
          wallet.connected && (
            <>
              {
                pages.map((page, index) => <StyledButton key={index} component={Link} to={page.route}>{page.label}</StyledButton>)
              }
            </>
          )
        }
        <WalletMultiButton></WalletMultiButton>
      </StyledNavBar>
    </StyledTopNav>
  )
};