import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTopNav = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
  borderBottom: `1px solid #323633`,
  '.wallet-adapter-button': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.black,
    fontFamily: 'GTAmerica',
    borderRadius: '8px'
  },
  'a': {
    textDecoration: 'none',
    '&.MuiButton-root': {
      color: `${theme.palette.primary.gray} !important`,
    },
    '&:first-of-type': {
      color: theme.palette.primary.gray
    }
  }
}));

export const StyledNavBar = styled(Box)(({ theme }) => ({
  width: '50%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  'a': {
    marginRight: '24px'
  }
}));


