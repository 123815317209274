import { PublicKey } from '@solana/web3.js';
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";

export const getMintInfo = async (connection, mint) => {
    let mintPubkey = new PublicKey(mint);
    let tokenmetaPubkey = await Metadata.getPDA(mintPubkey);

    const tokenmeta = await Metadata.load(connection, tokenmetaPubkey);
    return {
        name: tokenmeta?.data?.data?.name,
        url: tokenmeta?.data?.data?.uri
    }
};

export const getMintDetails =async (connection, mint) => {
    const mintInfo = await getMintInfo(connection, mint);
    return await getMintFromUrl(mintInfo.url);
}

export const getMintFromUrl = async (url) => {
    const data = await fetch(url, {
        headers: {
            'Accept': 'application/json'
        }

    });
   return await data.json();
};