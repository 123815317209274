import { Modal, Avatar, Divider, Button, TextField, Box, Select, MenuItem, InputLabel, FormControl, Grid, CircularProgress } from '@mui/material';
import { ErrorWrapper } from 'components/ErrorWrapper';
import { useForm, Controller } from 'react-hook-form';
import { StyledBox, StyledFooter, StyledHeader } from './styles';
import { getProvider, list_rental, SOLLIEN_PROGRAM_ID } from 'utils';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
  Program, Provider, web3, utils
} from '@project-serum/anchor';
import { PublicKey, Keypair, SystemProgram } from '@solana/web3.js';
import { useWalletNfts } from "@nfteyez/sol-rayz-react";
import { MINT, PAYMENT_MINT } from 'utils/constants';
import { TextInput } from 'components/TextInput';
import { SelectInput } from 'components/Select'
import { StyledButton } from 'components/Button';
import { useState, useEffect } from 'react';
import { Loader } from 'components/Loader';
import { getMintDetails } from 'dataProviders';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';


export const ListNFTModal = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [nftsWithMeta, setNfts] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [status, setStatus] = useState('success');
  const [showAlert, setShowAlert] = useState(false);
  const { sendTransaction } = useWallet();

  const defaultValues = {
    nft: '',
    paymentToken: '',
    duration: 0,
    rentPerDay: 0,
    deposit: 0
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues,
  });

  const wallet = useWallet();
  const { connection } = useConnection();

  const paymentMint = new PublicKey(PAYMENT_MINT);

  const onSave = async (data) => {

    setLoading(true);
    const { nft, duration, rentPerDay, deposit } = data;
    const multiplier = 10 ** 6;
    const mint = new PublicKey(nft);
    try {
      if (!wallet.connected) throw new WalletNotConnectedError();
      const tx = await list_rental(connection, wallet, { mint: mint, paymentMint: paymentMint, duration, rentPerDay: rentPerDay * multiplier, deposit: deposit * multiplier });
      const txSignature = await sendTransaction(tx, connection);
      const txHash = await connection.confirmTransaction(txSignature, 'processed');

      setLoading(false);
      setShowAlert(true);
      setAlertMessage("Transaction successful")
      setStatus('success');
    } catch (e) {
      console.log(e);
      setLoading(false);
      setShowAlert(true);
      setStatus('error');
      if (e instanceof WalletNotConnectedError) {
        setAlertMessage("Please connect wallet");
      } else {
        setAlertMessage(e.message);
      }
    }
  };

  const { nfts, isLoading, error } = useWalletNfts({
    publicAddress: wallet?.publicKey?.toString() || '',
    // pass your connection object to use specific RPC node
    connection: connection,
  });

  useEffect(async () => {
    const nftsWithMeta = await Promise.allSettled(nfts.map(async (nft) => await getMintDetails(connection, nft.mint)));
    const nftDetails = nftsWithMeta.map((nftWithMeta, index) => {
      const details = nftWithMeta.value;
      const mint = nfts[index].mint;
      return { ...details, mint }
    });
    console.log(nftDetails)
    setNfts(nftDetails);
  }, [isLoading]);

  // console.log(nfts[0].data, nftsWithMeta)

  if (error) {
    console.log("Error: ", error);
  }
  if (isLoading) {
    console.log("Is Loading: ", isLoading);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <StyledBox>
        <StyledHeader variant='h2'>List NFT</StyledHeader>
        {showAlert && <Alert sx={{ margin: '12px 0' }} severity={status} onClose={() => {setShowAlert(false)}}>{alertMessage}</Alert>}
        {
          (isLoading) ? (
            <Loader />
          ) : (
            <>
              <form>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <ErrorWrapper>
                      <Controller
                        name='nft'
                        control={control}
                        rules={{
                          required: true,
                        }}
                        haserrors={errors.nft}
                        render={({ field }) => (
                          <SelectInput
                            name='nft'
                            label='Select NFT'
                            fieldLabel='Select NFT'
                            {...field}
                          >
                            {nftsWithMeta.map(({ mint, name, image }, index) => (
                              <MenuItem key={index} value={mint}>
                                <Avatar alt={name} src={image} />
                                <Typography gutterBottom variant="h6" component="div">
                                  {name}
                                </Typography>
                              </MenuItem>
                            ))}
                          </SelectInput>
                        )}
                      />
                    </ErrorWrapper>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <ErrorWrapper>
                      <Controller
                        name='duration'
                        control={control}
                        rules={{
                          required: true,
                        }}
                        haserrors={errors.duration}
                        render={({ field }) => (
                          <TextInput
                            label='Duration (hours)'
                            type='number'
                            inputProps={{ min: 1 }}
                            fullWidth
                            {...field}
                          />
                        )}
                      />
                    </ErrorWrapper>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <ErrorWrapper>
                      <Controller
                        name='rentPerDay'
                        control={control}
                        rules={{
                          required: true,
                        }}
                        haserrors={errors.rentPerDay}
                        render={({ field }) => (
                          <TextInput
                            label='Rent per hour'
                            type='number'
                            fullWidth
                            inputProps={{ min: 1 }}
                            {...field}
                          />
                        )}
                      />
                    </ErrorWrapper>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <ErrorWrapper>
                      <Controller
                        name='deposit'
                        control={control}
                        rules={{
                          required: true,
                        }}
                        haserrors={errors.deposit}
                        render={({ field }) => (
                          <TextInput
                            label='Deposit'
                            type='number'
                            fullWidth
                            inputProps={{ min: 1 }}
                            {...field}
                          />
                        )}
                      />
                    </ErrorWrapper>
                  </Grid>
                </Grid>
              </form>
              <StyledFooter>
                <StyledButton
                  onClick={handleClose}
                  disabled={loading}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  size='large'
                  variant='contained'
                  onClick={handleSubmit(onSave)}
                  disabled={loading}
                >
                  {
                    loading ? <CircularProgress size={24} /> : 'Save'
                  }
                </StyledButton>
              </StyledFooter>
            </>
          )
        }
      </StyledBox>
    </Modal>
  );
};
