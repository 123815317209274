import { Box, styled } from '@mui/system';

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '40px',
  width: 500,
  background: theme.palette.primary.white,
  border: `0.1rem solid ${theme.palette.primary.gray50}`,
  color: theme.palette.primary.gray,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.04)',
  borderRadius: 8,
  boxSizing: 'border-box'
}));

export const StyledFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '24px',
  marginTop: '40px'
}));