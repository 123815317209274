import { createTheme } from '@mui/material/styles';

const palette = {
  primary: {
    main: '#74e1cf',
    white: '#FFFFFF',
    black: '#000000',
    red: '#DF2525',
    red75: '#E16058',
    gray: 'rgba(0, 0, 0, 0.87)',
    gray25: '#FFFFFF',
    gray50: '#FFFFFF',
    gray100: '#FFFFFF',
    gray200: '#FFFFFF',
    green: '#2A3A38',
    green100: '#74e1cf'
  },
  secondary: {
    lightBlue4: '#CCDDE7',
    main: '#959CA0',
    gray1: '#333333',
    gray4: '#444444',
  },
  tertiary: {
    main: '#EAECEC',
  },
};

const typography = {
  // Common configuration
  fontFamily: [
    "GTAmerica",
    "sans-serif"
  ].join(","),
  fontStyle: 'normal',
  lineHeight: '140%',

  // Headers
  h1: {
    fontSize: '30px',
    fontWeight: 600,
  },
  h2: {
    fontSize: '24px',
    fontWeight: 600,
  },
  h3: {
    fontSize: '22px',
    fontWeight: 600,
  },
  h4: {
    fontSize: '20px',
    fontWeight: 600,
  },
  h5: {
    fontSize: '18px',
    fontWeight: 600,
  },
  h6: {
    fontSize: '16px',
    fontWeight: 600,
  },
  // Paragraphs
  //// Bold
  pb1: {
    fontSize: '16px',
    fontWeight: 600,
  },
  pb2: {
    fontSize: '15px',
    fontWeight: 600,
  },
  pb3: {
    fontSize: '14px',
    fontWeight: 600,
  },
  pb4: {
    fontSize: '13px',
    fontWeight: 600,
  },
  pb5: {
    fontSize: '12px',
    fontWeight: 600,
  },
  pb6: {
    fontSize: '11px',
    fontWeight: 600,
  },
  //// Normal
  pn1: {
    fontSize: '16px',
    fontWeight: 500,
  },
  pn2: {
    fontSize: '15px',
    fontWeight: 500,
  },
  pn3: {
    fontSize: '14px',
    fontWeight: 500,
  },
  pn4: {
    fontSize: '13px',
    fontWeight: 500,
  },
  pn5: {
    fontSize: '12px',
    fontWeight: 500,
  },
  pn6: {
    fontSize: '11px',
    fontWeight: 500,
  },
  //// Light
  pl1: {
    fontSize: '16px',
    fontWeight: 400,
  },
  pl2: {
    fontSize: '15px',
    fontWeight: 400,
  },
  pl3: {
    fontSize: '14px',
    fontWeight: 400,
  },
  pl4: {
    fontSize: '13px',
    fontWeight: 400,
  },
  pl5: {
    fontSize: '12px',
    fontWeight: 400,
  },
  pl6: {
    fontSize: '11px',
    fontWeight: 400,
  },
};

const components = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'GTAmerica';
      }
    `,
  },
}

export const theme = createTheme({
  palette: palette,
  typography: typography,
  components: components
});

// Install this colorize extension in VSCode to see colors here!
// https://marketplace.visualstudio.com/items?itemName=kamikillerto.vscode-colorize
// config:
// .vscode/settings.json
// "colorize.languages": [
//     "javascript",
//   ]
