import { TopNav } from "components/TopNav";
import { Outlet } from "react-router-dom";
import { Container, Content } from './styles';

function Main() {
  return (
    <Container>
      <TopNav />
      <Content>
        <Outlet />
      </Content>
    </Container>
  );
};

export default Main;