import { web3, BN } from '@project-serum/anchor';
import {
  TokenManagerKind,
  InvalidationType,
  withRemainingAccountsForPayment,
} from '@onchain_org/token-manager/dist/cjs/programs/tokenManager';
import {
  findAta,
  withFindOrInitAssociatedTokenAccount,
  withIssueToken,
} from '@onchain_org/token-manager';
import { extendExpiration } from '@onchain_org/token-manager/dist/cjs/programs/timeInvalidator/instruction';
import { timeInvalidator, tokenManager } from '@onchain_org/token-manager/dist/cjs/programs';
import { addInvalidator } from '@onchain_org/token-manager/dist/cjs/programs/tokenManager/instruction';
import { CARDINAL_CRANK_ADDRESS, DEFAULT_EXTENSION } from './constants';
import { PublicKey } from '@solana/web3.js';

export const list_rental = async (
  connection,
  wallet,
  { mint, paymentMint, duration, rentPerDay, deposit }
) => {
  const transaction = new web3.Transaction();

  const issuerTokenAccountId = await withFindOrInitAssociatedTokenAccount(
    transaction,
    connection,
    mint,
    wallet.publicKey,
    wallet.publicKey
  );

  const issueTokenParameters = {
    // claimPayment: {
    //   paymentMint: paymentMint,
    //   paymentAmount: new BN(deposit),
    // },
    collateral: {
      collateralMint: paymentMint,
      collateralAmount: new BN(deposit),
    },
    timeInvalidation: {
      durationSeconds: 0,
      extension: {
        extensionPaymentAmount: new BN(rentPerDay),
        extensionDurationSeconds: DEFAULT_EXTENSION,
        extensionPaymentMint: paymentMint,
        disablePartialExtension: true,
      },
    },
    mint: mint, // NFT rental mint
    issuerTokenAccountId: issuerTokenAccountId,
    visibility: 'public', // default public means anyone can claim this rental
    kind: TokenManagerKind.Unmanaged, // used for metaplex master / editions,
    invalidationType: InvalidationType.Return, // indicates this token will be returned when invalidated
    customInvalidators: [new PublicKey(CARDINAL_CRANK_ADDRESS)]
  };
  const [issueTransaction] = await withIssueToken(transaction, connection, wallet, issueTokenParameters);
  return issueTransaction;
};
