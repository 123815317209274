import { styled } from '@mui/system';
import { FormHelperText } from '@mui/material';

export const ErrorMessage = styled(FormHelperText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.red75,
  marginTop: '5px',
  fontSize: '14px',
}));
