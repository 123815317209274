import { Box } from '@mui/system';
import { ErrorMessage } from './styles';

export const ErrorWrapper = ({ children, ...rest }) => (
  <Box {...rest}>
    {children}
    {(children.props.haserrors || children.props.hasErrors) && (
      <ErrorMessage>
        This field is required
      </ErrorMessage>
    )}
  </Box>
);