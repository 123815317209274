import { styled, Typography, Box, Paper } from "@mui/material";

export const TableHeading = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: '11px',
  color: theme.palette.primary.gray,
  fontWeight: 'bolder',
  fontFamily: 'GTAmerica'
}));

export const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  minWidth: '900px',
});

export const ListWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  flexWrap: 'wrap'
});

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));