import { useTheme } from '@emotion/react';
import { StyledWrapper, PageHeader } from './styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { List } from 'components/List';
import { Typography, Box } from '@mui/material';
import { getMyListings } from 'dataProviders';
import { getProvider } from 'utils';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { delist_rental } from 'utils/delist_rental';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { Loader } from 'components/Loader';
import { withdraw } from 'utils/withdraw';

function MyListings() {
  const [listings, setListings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(true);
  const [alertMessage, setAlertMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { sendTransaction } = useWallet();
  const { connection} = useConnection();

  const fetchData = useCallback(async (connection) => {
    const data = await getMyListings(connection, wallet.publicKey);
    setLoading1(false);
    setListings(data);
    console.log(data);
  }, []);

  const toggleApplyModal = async (listing) => {

    setLoading(true);
    const mint = listing?.mint;
    try {
      if (!wallet.connected) throw new WalletNotConnectedError();
      const tx = await withdraw(connection, wallet, { mint });
      const txSignature = await sendTransaction(tx, connection);
      const txHash = await connection.confirmTransaction(txSignature, 'processed');

      setShowAlert(true);
      setAlertMessage("Transaction successful")
    } catch (e) {
      console.log(e);
      setLoading(false);
      setShowAlert(true);
      if (e instanceof WalletNotConnectedError) {
        setAlertMessage("Please connect wallet");
      } else {
        setAlertMessage(e.message);
      }
    }
  }
  
  const wallet = useWallet();

  /* ComponentDidMount*/
  useEffect(() => {
    fetchData(connection);
  }, []);
  return (
    <StyledWrapper>
      <PageHeader>
        <Typography variant='h2' color='white'>My Listings</Typography>
      </PageHeader>
      {!loading1 ? (
        <List
          items={listings}
          btnText='Revoke'
          onApply={toggleApplyModal}
        />
      ) : (
        <Box sx={{ height: 'calc(100vh - 320px)' }}>
          <Loader color='primary' />
        </Box>
      )}
    </StyledWrapper>
  );
}

export default MyListings;