import { Box, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  width: '100%',
  height: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center'
});