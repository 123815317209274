import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getStyles } from './styles';
import { useTheme } from '@emotion/react';
import { Box, Chip, Stack } from '@mui/material';
import { StyledButton } from 'components/Button';

export default function StyledCard({ data, btnText, btnText2, onApply, onAction2 }) {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Card sx={styles.card}>
      <CardMedia
        component="img"
        height="300"
        image={data.mintInfo?.image}
        alt={data.mintInfo?.name}
      />
      <CardContent>
        <Stack direction="row" justifyContent='space-between'>
          <Typography gutterBottom variant="h4" component="div" sx={styles.header}>
            {data.mintInfo?.name}
          </Typography>
          <Chip size="small" label={data.status} color="primary" variant='outlined' />
        </Stack>
        <Stack direction="row" justifyContent='space-between' alignItems='center' sx={{ marginBottom: '8px' }}>
          <Typography gutterBottom variant="body2" component="div">
            Price per day
          </Typography>
          <Box sx={styles.fadeChip}>
            <Typography gutterBottom variant="body2" component="div">
              {data.dailyPrice} USDC
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent='space-between' alignItems='center' sx={{ marginBottom: '8px' }}>
          <Typography gutterBottom variant="body2" component="div">
            Initial Deposit
          </Typography>
          <Box sx={styles.fadeChip}>
            <Typography gutterBottom variant="body2" component="div">
              {data.securityDeposit} USDC
            </Typography>
          </Box>
        </Stack>
        { false && <Stack direction="row" justifyContent='space-between' alignItems='center' sx={{ marginBottom: '8px' }}>
          <Typography gutterBottom variant="body2" component="div">
            Max Rent Duration
          </Typography>
          <Box sx={styles.fadeChip}>
            <Typography gutterBottom variant="body2" component="div">
              {data.rentDuration} Days
            </Typography>
          </Box>
        </Stack>}
        {data.paidUntil > 0 && <Stack direction="row" justifyContent='space-between' sx={{ marginBottom: '8px' }}>
          <Typography gutterBottom variant="body" component="div">
            Rent Due
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {(new Date(data.paidUntil * 1000)).toLocaleString() }
          </Typography>
        </Stack>}
      </CardContent>
      <CardActions>
        <StyledButton
          variant='outlined'
          size="large"
          fullWidth
          onClick={onApply}
        >
          {btnText}
        </StyledButton>
        {
          btnText2 && <StyledButton
            variant='outlined'
            size="large"
            fullWidth
            onClick={onAction2}
          >
            {btnText2}
          </StyledButton>
        }
      </CardActions>
    </Card>
  );
}

StyledCard.defaultProps = {
  data: {
    name: '',
    imgUrl: ''
  },
  onApply: () => {},
  btnText: '',
}

StyledCard.propTypes = {
  data: PropTypes.objectOf({
    name: PropTypes.string,
    imgUrl: PropTypes.string,
    price: PropTypes.number,
    initialDeposit: PropTypes.number
  }),
  onApply: PropTypes.func,
  btnText: PropTypes.string,
}
