import { TokenManagerState } from '@onchain_org/token-manager/dist/cjs/programs/tokenManager';
import { PAYMENT_DECIMALS } from 'utils/constants';

export class SolRent {
    constructor(publicKey, tokenManager, claimApprover, timeInvalidator) {
        console.log(claimApprover?.tokenManager.toBase58())
        console.log(publicKey.toBase58(), tokenManager.issuer?.toBase58(), tokenManager.kind)
        this.publicKey = publicKey.toBase58();
        this.mint = tokenManager.mint.toBase58();
        this.state = tokenManager.state;
        this.renter = tokenManager.issuer.toBase58();
        // this.tokenAccount = accountData.tokenAccount.toBase58();
        
        // this.recipient = accountData.recipient ? accountData.recipient.toBase58() : '';
        this.recipientTokenAccount = tokenManager.recipientTokenAccount?.toBase58();
        this.claimApprover = tokenManager.claimApprover?.toBase58();
        this.timeInvalidator = tokenManager.invalidators[0]?.toBase58();
        
        // this.rentDuration = accountData.rentDuration.toNumber();
        this.securityDeposit = (claimApprover?.collateralAmount?.toNumber() || 0 )/ 10 ** PAYMENT_DECIMALS;
        this.paymentMint = claimApprover?.collateralMint?.toBase58();
        
        this.paymentToken = timeInvalidator?.paymentMint?.toBase58();
        this.dailyPrice = (timeInvalidator?.extensionPaymentAmount?.toNumber() || 0)/ 10 ** PAYMENT_DECIMALS;

        this.paidUntil = timeInvalidator?.expiration?.toNumber();

        // this.listedAt = accountData.listedAt ? accountData.listedAt.toNumber() : 0;
        // this.rentedAt = accountData.rentedAt ? accountData.rentedAt.toNumber() : 0;
        // this.revokedAt = accountData.revokedAt ? accountData.revokedAt.toNumber() : 0;
        // this.paidUntil = accountData.paidUntil ? accountData.paidUntil.toNumber() : 0;

        this.isCollateral = true;

        this.mintInfo = {};
    }

    setMintDetails(data) {
        this.mintInfo = data;
    } 

    setMintInfo(data) {
        this.mintInfo.uri = data.uri;
        this.mintInfo.name = data.name;
    } 

    get key() {
        return this.publicKey.toBase58()
    }

    get status() {
        switch (this.state) {
            case 0:
                return 'Initialized';
            case 1:
                return 'Listed';
            case 2:
                return 'Rented';
            case 3:
                return 'Returned';
            default:
                return 'Unknown';
        }
    }

}