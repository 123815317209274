import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50vh',
}));

export const Title = styled(Box)(({ theme }) => ({
  color: '#818a8e',
  fontWeight: 600,
  fontSize: '24px',
}));

export const InfoMessage = styled(Box)(({ theme }) => ({
  color: '#818a8e',
  fontWeight: 400,
  fontSize: '12px',
  marginTop: '10px',
  marginBottom: '24px',
  maxWidth: '300px',
  textAlign: 'center',
}));

