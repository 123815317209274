import { withInvalidate } from '@onchain_org/token-manager';
import { web3 } from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';

export const withdraw = async (connection, wallet, {
    mint
}) => {
    const transaction = new web3.Transaction();
    const withdrawTx = await withInvalidate(transaction, connection, wallet, new PublicKey(mint))
    withdrawTx.instructions[0].keys.map((i) => console.log(i.pubkey.toBase58()));
    return withdrawTx;
};
