import { styled, Box } from "@mui/material";

export const RowContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.gray200,
  margin: '10px 0px 10px 0px',
  padding: '0 10px 0 0',
  height: '60px',
  display: 'flex',
  justifyContent: 'space-between',
  border: `1px solid ${theme.palette.primary.gray15}`,
  borderRadius: '8px',
}));