import { StyledWrapper, PageHeader } from './styles';
import { useCallback, useEffect, useState } from 'react';
import { List } from 'components/List';
import { Typography, Box } from '@mui/material';
import { ApplyModal } from 'components/ApplyModal';
import { ListNFTModal } from 'components/ListNFTModal';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useWalletNfts } from "@nfteyez/sol-rayz-react";
import { StyledButton } from 'components/Button';
import { Loader } from 'components/Loader';
import { getTokenManagersByState } from '@onchain_org/token-manager/dist/cjs/programs/tokenManager/accounts';
import { TokenManagerState } from '@onchain_org/token-manager/dist/cjs/programs/tokenManager';
import { SolRent } from 'models/SolRent';
import { getListed } from 'dataProviders';


function Home() {
  const [showApplyModal, setShowApplyModal] = useState(false);
  const { publicKey} = useWallet();
  const [showListNFTTokenModal, setShowListNFTTokenModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState(false);
  const {connection} = useConnection({});
  const [listings, setListings] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleApplyModal = (listing) => {
    setShowApplyModal(!showApplyModal);
    setSelectedListing(listing);
  }

  const toggleListNFTTokenModal = () => setShowListNFTTokenModal(!showListNFTTokenModal);
  
  const wallet = useWallet();
  const fetchData = useCallback(async (connection) => {
    const data = await getListed(connection)
    console.log(data)
    if (data) {
      setLoading(false);
      setListings(data);
    }
  }, []);

  const { nfts, isLoading, error } = useWalletNfts({
    publicAddress: wallet?.publicKey?.toString() || '',
  });
  
  /* ComponentDidMount*/
  useEffect(() => {
    fetchData(connection);
  }, []);

  return (
    <StyledWrapper>
      <PageHeader>
        <Typography
          variant='h2'
          color='white'
        >
          Available Listings
        </Typography>
        {
          !loading && publicKey && (
            <StyledButton
              variant='contained'
              size='large'
              onClick={toggleListNFTTokenModal}
            >
              List token
            </StyledButton>
          )
        }
      </PageHeader>
      {
        !loading ? (
          <List
            items={listings}
            btnText="Rent"
            onApply={toggleApplyModal}
          />
        ) : (
          <Box sx={{ height: 'calc(100vh - 320px)' }}>
            <Loader color='primary' />
          </Box>
        )
      }
      <ApplyModal
        open={showApplyModal}
        handleClose={toggleApplyModal}
        listing = {selectedListing}
      />
      {showListNFTTokenModal && <ListNFTModal
        open={showListNFTTokenModal}
        handleClose={toggleListNFTTokenModal}
      />}
    </StyledWrapper>
  );
}

export default Home;