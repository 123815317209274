// This function will be used to read data from smart contracts
import { TokenManagerState } from '@onchain_org/token-manager/dist/cjs/programs/tokenManager';
import { getTokenManagers, getTokenManagersByState, getTokenManagersForIssuer } from '@onchain_org/token-manager/dist/cjs/programs/tokenManager/accounts';
import {
  BN,
  Program, Provider, web3
} from '@project-serum/anchor';
import { bs58 } from '@project-serum/anchor/dist/cjs/utils/bytes';
import { PublicKey } from '@solana/web3.js';
import { SolRent } from 'models/SolRent';
import { SOLLIEN_PROGRAM, SOLLIEN_PROGRAM_ID } from 'utils';
import { getMintDetails, getMintInfo } from './mint';
import { getClaimApprovers } from '@onchain_org/token-manager/dist/cjs/programs/claimApprover/accounts';
import { getCollateralManagers } from '@onchain_org/token-manager/dist/cjs/programs/collateralManager/accounts';
import { getTimeInvalidators } from '@onchain_org/token-manager/dist/cjs/programs/timeInvalidator/accounts';

// export const query = async (provider, filters = {}) => {
//   const programId = new PublicKey(SOLLIEN_PROGRAM_ID);
//   const program = new Program(SOLLIEN_PROGRAM, programId, provider);
//   const data = await program.account.solRent.all(filters)
//   const rentals = data.map(rental => new SolRent(rental.publicKey, rental.account));
  
//   const rentalWithMints = await Promise.allSettled(rentals.map(async (rental) => {
//     try {
//       const mintDetails = await getMintDetails(provider, rental.mint);
//       rental.setMintDetails(mintDetails);
//     } catch(e) { console.log(e)};
//     return rental;
//   }));

//   return rentalWithMints.map(rentalWithMint => rentalWithMint.value);
// };

const enrich = async (connection, listings) => {
  // const claimApproverIds = listings.map(listing => listing.parsed.claimApprover); 
  // const claimApprovers = await getClaimApprovers(connection, claimApproverIds);

  const claimApproverIds = listings.map(listing => listing.parsed.claimApprover).filter((c) => !!c);
  const claimApprovers = await getCollateralManagers(connection, claimApproverIds);

  const timeInvalidatorIds = listings.map(listing => listing.parsed.invalidators[0]); 
  const timeInvalidators = await getTimeInvalidators(connection, timeInvalidatorIds);
  
  const result =  await Promise.allSettled(listings.map(async listing => {
    // console.log(claimApprovers)
    const claimApprover = claimApprovers.find(claimApprover => claimApprover?.parsed?.tokenManager.toBase58() === listing.pubkey.toBase58())
    console.log(claimApprover)
    if (!claimApprover) {
      throw Error("No claim approver found");
    }
    const timeInvalidator = timeInvalidators.find(timeInvalidator => timeInvalidator?.parsed.tokenManager.toBase58() === listing.pubkey.toBase58())
    const solrent = new SolRent(listing.pubkey, listing.parsed, claimApprover?.parsed, timeInvalidator?.parsed);

    console.log(solrent)
    try {
      const mintDetails = await getMintDetails(connection, solrent.mint);
      solrent.setMintDetails(mintDetails);
    } catch(e) { console.log(e)};
      return solrent;
  }));

  console.log(result)
  return result.filter(rentalWithMint => rentalWithMint.status === 'fulfilled').map(rentalWithMint => rentalWithMint.value);
}


export const getListed = async (connection) => {
  // const filters = [{
  //   "memcmp": {
  //     "offset": 303,
  //     "bytes": bs58.encode(Buffer.from([1])),
  //   }
  // }];

  // return query(provider, filters);

  const listings = await getTokenManagersByState(connection, TokenManagerState.Issued)
  return enrich(connection, listings);

};

// export const getRented = async (provider) => {
//   const filters = [{
//     "memcmp": {
//       "offset": 383,
//       "bytes": bs58.encode(Buffer.from([2])),
//     }
//   }];

//   return query(provider, filters);
// }

export const getMyListings = async (connection, owner) => {
  // const filters = [{
  //   "memcmp": {
  //     "offset": 8,
  //     "bytes": owner,
  //   }
  // }, {
  //   "memcmp": {
  //     "offset": 303,
  //     "bytes": bs58.encode(Buffer.from([1])),
  //   }
  // }];

  // console.log(filters);
  // return query(provider, filters);

  const listings = await getTokenManagersForIssuer(connection, new PublicKey(owner))
  return enrich(connection, listings);
}

export const getMyRentals = async (connection, owner) => {
  // const filters = [{
  //   "memcmp": {
  //     "offset": 169,
  //     "bytes": owner,
  //   }
  // }, {
  //   "memcmp": {
  //     "offset": 383,
  //     "bytes": bs58.encode(Buffer.from([2])),
  //   }
  // }];

  // return query(provider, filters);

  const listings = await getTokenManagersByState(connection, TokenManagerState.Claimed)
  const filteredListings = listings.filter(listing => listing.parsed.recipient?.toBase58() === owner.toBase58())
  return enrich(connection, filteredListings);
}